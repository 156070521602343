<template>
  <div class="media-card" :class="type.toLowerCase()">
    <div class="file-upload">
      <div class="form-group">
        <label class="control-label" v-html="label"></label>
        <input
          type="file"
          v-if="isEditable"
          :accept="acceptedMimeTypes.join(',')"
          :name="fieldName"
        />

        <small class="help-block">
          <slot name="help"></slot>
          Type must be one of:
          <code
            v-for="mimeType in acceptedMimeTypes"
            :key="mimeType"
            v-text="mimeType"
          ></code
          >.
          <template v-if="hasFileSize">
            Must be under <code v-text="humanizedMaxFileSize"></code> in size.
          </template>
        </small>

        <slot name="description"></slot>
      </div>
    </div>

    <div class="file-preview" v-if="hasPreviewURL">
      <a :href="url" target="_blank" download v-if="isType('pdf')">
        <b>Download current PDF</b>
      </a>

      <audio
        controls
        preload="none"
        :src="url"
        v-else-if="isType('audio')"
      ></audio>

      <video
        muted
        autoplay
        playsinline
        loop
        preload="auto"
        v-else-if="isType('video')"
      >
        <source :src="url" type="video/mp4" />
      </video>

      <img :src="url" v-else-if="isType('image', 'svg')" />

      <div class="checkbox" v-if="isRemovable">
        <label>
          <input type="checkbox" :name="`_delete_${fieldName}`" value="1" />
          Delete this file on save.
        </label>
      </div>
    </div>
  </div>
</template>

<script>
const SUPPORTED_TYPES = ['image', 'video', 'audio', 'pdf', 'svg'];

const ACCEPT_MIME_MAPPING = {
  svg: ['image/svg+xml'],
  image: ['image/png', 'image/jpg', 'image/jpeg'],
  audio: ['audio/mpeg', 'audio/mp3'],
  video: ['video/mp4'],
  pdf: ['application/pdf'],
};

export default {
  props: {
    type: {
      required: true,
      type: String,
      validate: value => SUPPORTED_TYPES.includes(value.toLowerCase()),
    },
    label: {
      required: true,
      type: String,
    },
    fieldName: {
      required: true,
      type: String,
    },
    url: {
      required: false,
      type: String,
    },
    removable: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    maxFileSizeInKb: {
      required: false,
      type: Number,
      default: () => -1,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    acceptedMimeTypes() {
      return ACCEPT_MIME_MAPPING[this.type.toLowerCase()];
    },

    humanizedMaxFileSize() {
      if (this.maxFileSizeInKb < 1024) {
        return `${this.maxFileSizeInKb}K`;
      }

      const maxFileSizeInMb = Math.floor(this.maxFileSizeInKb / 1024);

      return `${maxFileSizeInMb}M`;
    },

    hasPreviewURL() {
      return (this.url || '').trim().length > 0;
    },

    isEditable() {
      return !this.readonly;
    },

    isRemovable() {
      return this.isEditable && this.removable;
    },

    hasFileSize() {
      return this.maxFileSizeInKb > 0;
    },
  },

  methods: {
    isType(...targets) {
      return targets
        .map(t => t.toLowerCase())
        .includes(this.type.toLowerCase());
    },
  },
};
</script>

<style lang="scss" scoped>
.media-card {
  background: #fafafa;
  border: 1px solid #eee;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 1rem;

  &.audio {
    flex-direction: column;
  }

  &:not(.audio) {
    align-items: flex-start;
  }

  .file-preview {
    flex-shrink: 0;
  }
}

audio {
  width: 100%;
}

video,
img {
  display: block;
  max-width: 150px;
  max-height: 100px;
  margin-left: 1rem;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
